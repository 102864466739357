import "./login.scss";
import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer/footer";
import Navbar from "../../common/Navbar/navbar";

import Form from "../../common/Form/form";
import LinkContainer from "../../common/LinkContainer/linkContainer";

import authModel from "../../models/authModel";
import fieldList from "./fieldList.json";

import userAuth from "../../hooks/userAuth";
import jwt_decode from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState({ mail: "", password: "" });
  const [message, setMessage] = useState("");

  const login = authModel.useLogin();

  const submit = () => {
    login.fetch(auth.mail, auth.password);
  };

  useEffect(() => {
    if (login.res.status === "load") {
      if (login.res.data.status) {
        const token = login.res.data.JWT;
        const decoded = jwt_decode(token);
        if (decoded.statusId === 1) {
          userAuth.logIn(token);
          navigate("/home");
        } else if (decoded.statusId === 2) {
          setMessage(
            "* Su usuario se encuentra deshabilitado, contacte al administrador."
          );
        } else if (decoded.statusId === 3) {
          setMessage("* Su usario aun no se encuentra habilitado.");
        } else {
          setMessage("* Error de authenticacion.");
        }
      } else {
        setMessage("* El correo o contraseña ingresada son incorrectos.");
      }
    } else if (login.res.status === "error") {
      setMessage("* El correo o contraseña ingresada son incorrectos.");
    }
  }, [login.res.status]);

  return (
    <Fragment>
      <Navbar />
      <div className="login">
        <div className="login-container">
          <h1 className="login-title">Iniciar Sesion</h1>
          {message ? <small className="login-message">{message}</small> : null}

          <Form
            list={fieldList}
            data={auth}
            setData={setAuth}
            submit={submit}
          />
          <div className="login-link-container">
            <LinkContainer
              message="No tiene cuenta? Registrese"
              path="/register"
            />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Login;
