import "./footer.scss";
import React from "react";
import logo from "../../assets/img/logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <p className="footer-text">{"COPYRIGHT 2021"}</p>
      </div>
      <div>
        <a href={"http://integralabs.ai"}>
          <img className="footer-logo" src={logo} alt={"Integra Labs"} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
